<template>
    <div class="help-container">
        <div class="help-box" :class="{hide: isSuccess}">
            <div class="help-img">
                <img class="img" src="../../assets/common/help.png" alt="">
                <div class="text">
                    <div>{{t("form.message")}}</div>
                    <img src="../../assets/common/right-arrow.png" alt="">
                </div>
            </div>
            <div class="help-form">
                <div id="form" class="form">
                    <div class="input-box">
                        <span class="require">*</span>
                        <input class="require-input" :class="{active: active, error: !form.firstName.trim()}" v-model="form.firstName" type="text" :placeholder="t('form.firstName')">
                    </div>
                    <div class="input-box">
                        <span class="require">*</span>
                        <input class="require-input" :class="{active: active, error: !form.lastName.trim()}" v-model="form.lastName" type="text" :placeholder="t('form.lastName')">
                    </div>
                    <div class="input-box">
                        <span class="require">*</span>
                        <input class="require-input" :class="{active: active, error: !form.email.trim() || emailError}" @keyup="inputChange($event)" v-model="form.email" type="text" :placeholder="t('form.email')">
                    </div>
                    <div class="input-box">
                        <!-- <span class="require">*</span> -->
                        <input class="require-input" v-model="form.phone" type="text" :placeholder="t('form.phone')">
                    </div>
                    <div class="input-box">
                        <span class="require">*</span>
                        <input class="require-input" :class="{active: active, error: !form.company.trim()}" v-model="form.company" type="text" :placeholder="t('form.company')">
                    </div>
                    <div class="input-box">
                        <span class="require">*</span>
                        <input class="require-input" :class="{active: active, error: !form.job.trim()}" v-model="form.job" type="text" :placeholder="t('form.job')">
                    </div>
                    <div class="input-box">
                        <span class="require">*</span>
                        <input class="require-input" :class="{active: active, error: !form.country.trim()}" v-model="form.country" type="text" :placeholder="t('form.country')">
                    </div>
                    <div class="input-box textarea">
                        <!-- <span class="require">*</span> -->
                        <textarea class="require-input" v-model="form.comments" type="text" :placeholder="t('form.comments')"></textarea>
                    </div>
                    <div class="input-box btn">
                        <button id="formBtn" @click="submit">{{t('form.btnMessage')}}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="help-thanks" :class="{active: isSuccess}">
            <div class="d-text-24">{{t("form.thanks_message1")}}</div>
            <div class="d-text-18">{{t("form.thanks_message2")}}</div>
        </div>
    </div>
</template>
<script>
import { ref, reactive} from 'vue'
import { useI18n } from "vue-i18n";
import { addContact } from '../../api/api'
export default {
    name: 'HelpForm',
    setup() {
        const { t } = useI18n();
        
        let form = reactive({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            company: '',
            job: '',
            country: '',
            comments: ''
        })
        let err = reactive({
            firstName: false,
            lastName: false,
            email: false,
            company: false,
            job: false,
            country: false
        })
        let active = ref(false)
        let emailError = ref(false)
        let isSuccess = ref(false)
        return {
            form,
            err,
            active,
            emailError,
            isSuccess,
            t,
        }
    },
    methods: {
        inputChange (e) {
            let reg =  new RegExp(/\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/);
            if (!reg.test(e.target.value)) {
                this.emailError = true
            } else {
                this.emailError = false
            }
        },
        submit() {
            this.active = true
            let reg = /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
            this.err = {
                firstName: !this.form.firstName.trim(),
                lastName: !this.form.lastName.trim(),
                email: !this.form.email.trim() || !reg.test(this.form.email.trim()),
                company: !this.form.company.trim(),
                job: !this.form.job.trim(),
                country: !this.form.country.trim()
            }
            if (Object.keys(this.err).map(e => { return this.err[e] }).some(e=>{return e}) || this.emailError) {
                return
            }
            let data = {
                firstname: this.form.firstName,
                lastname: this.form.lastName,
                email: this.form.email,
                phone: this.form.phone,
                company: this.form.company,
                jobTitle: this.form.job,
                country: this.form.country,
                comments: this.form.comments
            }
        
            addContact(data).then(res => {
                if (res.code == 200) {
                    var user_id= localStorage.getItem('userId');
                    dataLayer.push({
                        'event':'purchase',
                        'userId':user_id,
                        'action':'Submit',
                        'currency': 'USD',
                        'transaction_id': 'WS_0001',
                        'value': 0,
                        'items': [
                            {
                            'item_id': 'World_Spire',
                            'item_name': 'WorldSpire',
                            }
                        ]
                    });
                    this.isSuccess = true
                }
            })
            // fetch('http://192.168.0.92/worldspire/subscribe/add').then(res => {console.log(res)})
            // let fetchUrl = 'http://192.168.0.92/worldspire/subscribe/add'
            // let data = {
            //     email: '123123@123.com'
            // }
            // fetch(fetchUrl, {
            //     method: "POST",
            //     headers: new Headers({
            //         'Content-type': 'application/json'
            //     }),
            //     body: JSON.stringify(data)
            // }).then(res => {
            //     return res.json()
            // }).then(res => {
            //     console.log(res)
            // })
        }
    },
}
</script>
<style lang="less">
.help-container {
    background: #F4F5F5;
    .help-box {
        height: 500px;
        &::after {
            content: '';
            display: block;
            clear: both;
        }
        .help-img {
            float: left;
            width: 650px;
            height: 500px;
            position: relative;
            .img {
                height: 500px;
            }
            .text {
                position: absolute;
                top: 40%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 60%;
                font-size: 50px;
                font-weight: bold;
                color: #ffffff;
                div {
                    width: 70%;
                    float: left;
                }
                img {
                    width: 30px;
                    float: left;
                    position: absolute;
                    bottom: 15px;
                    right: 0;
                }
            }
        }
        .help-form {
            float: left;
            width: e("calc(100% - 650px)");
            max-width: 815px;
            min-width: 650px;
            padding: 27px 70px;
            .form {
                width: 100%;
                // margin: 35px 0 0 70px;
                &::after {
                    content: '';
                    display: block;
                    clear: both;
                }
                input {
                    background: #FFFFFF;
                    border-radius: 2px;
                    border: 1px solid #DDDDDD;
                    // width: 330px;
                    width: 100%;
                    height: 50px;
                    padding: 0 30px;
                    font-family:  Proxima Nova, 'sourcehansans';
                    &.active.error {
                        border-color: #ff0000;
                    }
                }
                textarea {
                    width: 100%;
                    height: 100px;
                    border-radius: 2px;
                    border: 1px solid #DDDDDD;
                    font-family:  Proxima Nova, 'sourcehansans';
                    padding: 20px 30px;
                    resize: vertical;
                }
                .input-box {
                    position: relative;
                    float: left;
                    width: e("calc((100% - 15px) / 2)");
                    margin: 0 15px 15px 0;
                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                    .require {
                        position: absolute;
                        left: 20px;
                        top: 17px;
                        color: #ff0000;
                    }
                    &.textarea {
                        width: 100%;
                    }
                    &.btn {
                        button { 
                            width: 190px;  
                            height: 50px;
                            line-height: 50px;
                            text-align: center;
                            font-size: 18px;
                            font-family:  Proxima Nova, 'sourcehansans';
                            color: #ffffff;
                            border: none;
                            background: #ff9333;
                            border-radius: 24px;
                            cursor: pointer;
                            &:active {
                                background: rgba(255, 147, 51, 0.7);
                            }
                        }
                    }
                }
            }
        }
        &.hide {
            height: 0;
            overflow: hidden;
            transition: height 0.5s ease-in-out;
        }
    }
    .help-thanks {
        width: 100%;
        height: 0;
        overflow: hidden;
        background: #FFBE85;
        color: #ffffff;
        // padding: 30px 0;
        text-align: center;
        .d-text-24 {
            margin-top: 30px;
            font-size: 24px;
        }
        .d-text-18 {
            font-size: 18px;
        }
        &.active {
            height: 120px;
            transition: height 0.5s ease-in-out;
        }
    }
}
</style>
